// src/components/AIChat.tsx
import React, { useEffect, useState, useRef } from "react";
import { TextField, Button, Container, Grid, Box, LinearProgress, CircularProgress } from "@mui/material";
import Message from "./Message";
import OpenAI from "openai";
import { MessageDto } from "../models/MessageDto";
import SendIcon from "@mui/icons-material/Send";

interface ChatProps {
  darkMode: boolean;
}

const ZooChat: React.FC<ChatProps> = ({ darkMode }) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const scrollRef = useRef(null);
  darkMode = true;

  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    setMessages([
      {
        content: "Hi, I'm Bulby your Brainstorming Buddy. Ready to work on the our code & ethics video games?",
        isUser: false,
      },
    ]);
  }, [assistant]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const assistant = await openai.beta.assistants.retrieve(
      "asst_N37SaEul9SFTfQUCnPtkTUPr"
    );

    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput("");

    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === "in_progress" || response.status === "queued") {
      console.log("waiting...");
      setIsWaiting(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    const messageList = await openai.beta.threads.messages.list(thread.id);

    const lastMessage = messageList.data
      .filter((message: any) => message.run_id === run.id && message.role === "assistant")
      .pop();

    if (lastMessage) {
      console.log(lastMessage.content[0]["text"].value);
      setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <Container>
      <Grid container direction="column" spacing={2} paddingBottom={2}>
        <Box sx={{ height: '450px', overflowY: 'auto' }}>
          <Grid container direction="column" spacing={2} paddingBottom={2}>
            {messages.map((message, index) => (
              <Grid item xs={12} key={index} style={{ display: "flex", justifyContent: message.isUser ? "flex-end" : "flex-start" }}>
                <Message key={index} message={message} darkMode={darkMode} />
              </Grid>
            ))}
            <div ref={scrollRef} />
          </Grid>
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" style={{ paddingBottom: "2rem" }}>
        <Grid item xs={9} sm={11}>
          <TextField
            label="Chat with Bulby✨"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            style={{ width: "98%" }}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item xs={3} sm={1}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={{ background: "#ffc211" }}
            onClick={handleSendMessage}
            disabled={isWaiting}
          >
            {isWaiting && <CircularProgress color="inherit" />}
            {!isWaiting && <SendIcon style={{ fill: "black" }} fontSize="large" />}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ZooChat;