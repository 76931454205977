import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Chat from './components/Chat';
import ZooChat from './components/ZooChat';
import AIChat from './components/AIChat';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const Header: React.FC<{ darkMode: boolean }> = ({ darkMode }) => {
  const location = useLocation();
  const isZooChat = location.pathname === '/zoo';
  const isAIEthicsChat = location.pathname === '/ai-ethics';

  return (
    <header className="App-header">
      <div className="flex justify-center items-center">
        <img
          src={
            isZooChat
              ? (darkMode
                  ? "https://cdn.sanity.io/images/rbhjt84j/production/e1535d8a344ef046d7f474a6cbd23dbebacaa816-1728x720.png"
                  : "https://cdn.sanity.io/images/rbhjt84j/production/718cec0dcd324ad6fc078ced9a2ad24748d171b3-1728x720.png")
              : isAIEthicsChat
              ? (darkMode
                  ? "https://cdn.sanity.io/images/rbhjt84j/production/2ab0c9980e4297c6456ce71102cb56210cdb059d-1728x720.png"
                  : "https://cdn.sanity.io/images/rbhjt84j/production/abf0c2c46a148189a06f88f027878cc88bcd2a33-1728x720.png")
              : (darkMode
                  ? "https://cdn.sanity.io/images/rbhjt84j/production/be356f16c2b54da063ce3365fc38d5894d1fc6ec-1728x720.png"
                  : "https://cdn.sanity.io/images/aqwzz2yo/production/11f663f55b70c626273d777285dfb9d6cabcb8d7-1728x720.png")
          }
          alt="Logo"
          className="lg:w-1/3 w-1/2 mb-6 mt-2 h-auto"
        />
      </div>
    </header>
  );
};

const AppContent: React.FC<{ darkMode: boolean; setDarkMode: React.Dispatch<React.SetStateAction<boolean>> }> = ({ darkMode, setDarkMode }) => {
  const [open, setOpen] = useState(true);
  const [password, setPassword] = useState('');
  const location = useLocation();

  // Force dark mode if the route is "/ai-ethics"
  useEffect(() => {
    if (location.pathname === '/ai-ethics') {
      setDarkMode(true);
    }
  }, [location.pathname, setDarkMode]);

  // Determine the correct password based on the current route
  const correctPassword = useMemo(() => {
    if (location.pathname === '/') {
      return 'wally';  // Password for the main page
    } else if (location.pathname === '/zoo') {
      return 'kimarnold';   // Password for the zoo page
    } else if (location.pathname === '/ai-ethics') {
      return 'erie21';     // Password for the AI ethics page
    } else {
      return '';
    }
  }, [location.pathname]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password === correctPassword) {
      setOpen(false);
    } else {
      alert('Incorrect password');
    }
  };

  const dialogStyle = darkMode ? { backgroundColor: '#333', color: '#fff' } : { backgroundColor: '#fff', color: '#000' };

  return (
    <div className="App">
      {open && (
        <Dialog open={open} onClose={handleSubmit} PaperProps={{ style: dialogStyle }}>
          <DialogTitle>Enter Password</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div>
        {/* Only allow toggling dark mode when not on the AI ethics page */}
        {location.pathname !== '/ai-ethics' && (
          <IconButton onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        )}
      </div>
      <Header darkMode={darkMode} />
      <main>
        <Routes>
          <Route path="/" element={!open ? <Chat darkMode={darkMode} /> : null} />
          <Route path="/zoo" element={!open ? <ZooChat darkMode={darkMode} /> : null} />
          <Route path="/ai-ethics" element={!open ? <AIChat darkMode={darkMode} /> : null} />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent darkMode={darkMode} setDarkMode={setDarkMode} />
      </Router>
    </ThemeProvider>
  );
};

export default App;