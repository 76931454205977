// src/components/Chat.tsx
import React, { useEffect, useState, useRef } from "react";
import { TextField, Button, Container, Grid, Box, LinearProgress, CircularProgress } from "@mui/material";
import Message from "./Message";
import OpenAI from "openai";
import { MessageDto } from "../models/MessageDto";
import SendIcon from "@mui/icons-material/Send";

interface ChatProps {
  darkMode: boolean;
}


const Chat: React.FC<ChatProps> = ({ darkMode })  => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const scrollRef = useRef(null);


  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    setMessages([
      {
        content: "Hi, I'm Bulby your brainstorming buddy. Are you ready to get start asking questions?",
        isUser: false,
      },
    ]);
  }, [assistant]);

  useEffect(() => {
  scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
}, [messages]);


  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    // Create an assistant
    const assistant = await openai.beta.assistants.retrieve(
      "asst_zBSoxbSDPCukLtcV73Onr7QC"
    );

    // Create a thread
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput("");

    // Send a message to the thread
    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input,
    });

    // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    // Create a response
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
      console.log("waiting...");
      setIsWaiting(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(thread.id);

    // Find the last message for the current run
    const lastMessage = messageList.data
      .filter((message: any) => message.run_id === run.id && message.role === "assistant")
      .pop();

    // Print the last message coming from the assistant
    if (lastMessage) {
      console.log(lastMessage.content[0]["text"].value);
      setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
    }
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <Container>
      <Grid container direction="column" spacing={2} paddingBottom={2}>
        <Box sx={{ height: '450px', overflowY: 'auto' }}>
        <Grid container direction="column" spacing={2} paddingBottom={2}>
          {messages.map((message, index) => (
            <Grid item alignSelf={message.isUser ? "flex-end" : "flex-start"} key={index}>
              <Message key={index} message={message} darkMode={darkMode}  />
            </Grid>
          ))}
              <div ref={scrollRef} />
        </Grid>
      </Box>
      </Grid>
      <Grid container direction="row" paddingBottom={5} justifyContent={"space-between"}>
        <Grid item sm={11} xs={9}>
          <TextField
            label="Chat with Bulby"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            style={{width:"98%"}}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item sm={1} xs={3}>
          <Button  variant="contained" size="large" color="primary" style={{background:"#ffc211"}} onClick={handleSendMessage} disabled={isWaiting}>
            {isWaiting && <CircularProgress color="inherit" />}
            {!isWaiting && <SendIcon style={{fill:"black"}} fontSize="large" />}
          </Button>
        </Grid>
         <div><p className="text-xs text-zinc-400 mt-2 mb-6">Disclaimer: Bulby is an experimental AI character currently in beta testing. While every effort has been made to ensure its accuracy and reliability, please be aware that Bulby's responses are generated by machine learning algorithms and may not always be perfect. We are not responsible for any inaccuracies, errors, or unintended outputs produced by Bulby. Please use the information provided by Bulby at your own discretion and verify any important details independently. </p></div>
      </Grid>
    </Container>
  );
};

export default Chat;
